import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuGroup,
  VStack,
  IconButton,
} from '@chakra-ui/react';
import { Chat, Envelope, Help, HelpOutline } from '@airhelp/icons';
import { fontFamilyFraktion } from '@airhelp/design-tokens';
import { TrackingContext } from 'contexts';
import { useTrackEvent } from 'utils/tracking/hooks';
import { useLocale } from 'contexts/LocaleContext/LocaleContextProvider';
import { useZowie } from 'contexts/ZowieContext';
import { isRtl } from 'utils/textDirection/isRtl';
import config from 'config';
import HelpActionItem from './HelpActionItem.tsx';

const HelpAction: React.FC = () => {
  const { t } = useTranslation();
  const { subPageType } = useContext(TrackingContext);
  const { trackCtaClick } = useTrackEvent();

  const { openZowieChat } = useZowie();
  const { locale } = useLocale();

  return (
    <Menu
      isLazy
      closeOnBlur
      closeOnSelect
      autoSelect={false}
      onOpen={() => {
        trackCtaClick('help menu', subPageType);
      }}
    >
      <MenuButton
        aria-label="HelpOutline"
        icon={<HelpOutline color="greyscale.600" boxSize={7} />}
        size="lg"
        position="relative"
        display="flex"
        width={8}
        height={8}
        minWidth={8}
        minHeight={8}
        justifyContent="center"
        alignItems="center"
        borderRadius="full"
        backgroundColor={{ base: 'greyscale.100', md: 'transparent' }}
        border="1px solid"
        borderColor="greyscale.300"
        _hover={{ borderColor: 'greyscale.500' }}
        _focus={{ borderColor: 'greyscale.500' }}
        _active={{ borderColor: 'greyscale.500' }}
        as={IconButton}
        data-testid="toggle-help-menu"
      />
      <MenuList
        width={{ base: '280px', md: '325px' }}
        backgroundColor="greyscale.100"
        py={1}
        px={1}
        data-testid="help-action-dropdown"
      >
        <MenuGroup
          title={t('common.support.support')}
          textTransform="uppercase"
          color="greyscale.600"
          fontWeight="700"
          fontSize="11px"
          fontFamily={fontFamilyFraktion}
          px={5}
          py={4}
          m={0}
        >
          <VStack gap={1}>
            <HelpActionItem
              icon={<Chat />}
              title={t('common.support.chat')}
              onClick={openZowieChat}
              isRtl={isRtl(locale)}
              dataTestId="support-box-chat"
            />
            <HelpActionItem
              icon={<Envelope />}
              title={t('common.support.customer_service')}
              link={config.contactUrl}
              isRtl={isRtl(locale)}
              dataTestId="support-box-customer-support"
            />
            <HelpActionItem
              icon={<Help />}
              title={t('common.support.faq')}
              link={config.helpUrl.replace('{{locale}}', locale)}
              isRtl={isRtl(locale)}
              dataTestId="support-box-faq"
            />
          </VStack>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

export default HelpAction;

import { Flex, Heading, HStack, Link } from '@chakra-ui/react';
import { cloneElement, type PropsWithChildren, type ReactElement } from 'react';
import { AltArrowForward } from '@airhelp/icons';
import { NavLink } from 'react-router';

export function HelpActionItem({
  icon,
  title,
  link,
  onClick,
  dataTestId,
  isRtl = false,
}: PropsWithChildren<{
  icon: ReactElement<{ color: string; width: string; height: string }>;
  title: string;
  link?: string;
  dataTestId?: string;
  onClick?: () => void;
  isRtl?: boolean;
}>) {
  const styledIcon = cloneElement(icon, {
    color: 'primary.500',
    width: '20px',
    height: '20px',
  });

  const isLinkExternal = link?.startsWith('http');
  const ItemLink = onClick
    ? ({ children }: PropsWithChildren) => children
    : ({ children }: PropsWithChildren) => (
        <Link
          as={isLinkExternal ? undefined : NavLink}
          href={link}
          isExternal={isLinkExternal}
          display="inline-block"
          w="full"
        >
          {children}
        </Link>
      );

  return (
    <ItemLink>
      <HStack
        p={3}
        borderRadius="md"
        onClick={onClick}
        w="full"
        cursor={onClick ? 'pointer' : undefined}
        _hover={{ bg: 'greyscale.200' }}
        data-testid={dataTestId}
      >
        <Flex
          justify="center"
          align="center"
          w="40px"
          h="40px"
          padding="10px"
          rounded="md"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="greyscale.300"
        >
          {styledIcon}
        </Flex>
        <Heading
          fontSize="sm"
          fontWeight="500"
          color="greyscale.700"
          width="100%"
          as="h5"
        >
          {title}
        </Heading>
        <AltArrowForward
          width={5}
          height={5}
          color="greyscale.600"
          data-testid="arrow-forward-icon"
          transform={`rotate(${isRtl ? '180deg' : '0'})`}
        />
      </HStack>
    </ItemLink>
  );
}
